<template>
  <div>
    <b-row>
      <b-col lg="4">
        <div class="iq-card">
          <div class="iq-card-body pb-1">
            <div class="d-flex align-items-center justify-content-between">
              <p class="font-size-26 p-0 m-0">{{ $t("userProfile.orders") }}</p>
            </div>
            <p class="font-size-30 text-end m-0">
              {{ ordersStats.total }}
            </p>
          </div>
        </div>
      </b-col>
      <b-col lg="4">
        <div class="iq-card">
          <div class="iq-card-body pb-1">
            <div class="d-flex align-items-center justify-content-between">
              <p class="font-size-26 p-0 m-0">
                {{ $t("userProfile.pending_orders") }}
              </p>
            </div>
            <p class="font-size-30 text-end m-0">
              {{ ordersStats.in_progress }}
            </p>
          </div>
        </div>
      </b-col>
      <b-col lg="4">
        <div class="iq-card">
          <div class="iq-card-body pb-1">
            <div class="d-flex align-items-center justify-content-between">
              <p class="font-size-26 p-0 m-0">
                {{ $t("userProfile.success_orders") }}
              </p>
            </div>
            <p class="font-size-30 text-end m-0">
              {{ ordersStats.delivered }}
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="3" cols="12">
        <input-form
          class="mb-3 order-label"
          type="text"
          :placeholder="$t('main.order_num')"
          name="orderNum"
          v-model="params.order_num"
        >
          <template #append>
            <i class="fas fa-search"></i>
          </template>
        </input-form>
      </b-col>
      <b-col lg="3" cols="12">
        <main-select
          :options="status"
          class="order-select"
          :placeholder="$t('main.status')"
          label="name"
          :reduce="(data) => data.value"
          @selecting="(data) => (params.status = data.value)"
        />
      </b-col>
      <b-col lg="3" cols="12">
        <main-select
          :options="sort"
          class="order-select"
          :placeholder="$t('main.sort')"
          :label="$i18n.locale === 'en' ? 'name_en' : 'name_ar'"
          :reduce="(data) => data.value"
          @selecting="(data) => (params.sort = data.value)"
        />
      </b-col>
      <b-col lg="3" cols="12">
        <app-button
          @click.native="getOrders()"
          class="text-white d-flex align-items-center w-100 order-search-btn"
        >
          {{ $t("main.search") }}
        </app-button>
      </b-col>
    </b-row>

    <div v-if="loadingData" class="d-flex justify-content-center mb-2">
      <spinner-loading />
    </div>
    <div v-else>
      <div v-if="orders.length">
        <iq-card
          class="py-3 px-3 position-relative"
          v-for="order in orders"
          :key="order.id"
          v-b-toggle="`more-content-${order.id}`"
        >
          <b-row v-if="order.id !== selectedId">
            <b-col lg="3">
              <div class="d-flex flex-column">
                <p class="m-0 p-0 font-size-16 text-secondary">
                  {{ $t("marketplace.orderNumber") }}
                </p>
                <p class="m-0 p-0 font-size-18 text-muted">
                  {{ order.order_number }}
                </p>
              </div>
            </b-col>
            <b-col lg="4">
              <div class="d-flex flex-column">
                <span class="font-size-16 text-secondary">{{
                  $t("marketplace.orderDate")
                }}</span>
                <p class="m-0 p-0 font-size-18 text-muted">
                  {{ order.created_at | formateDate }}
                </p>
              </div>
            </b-col>
            <b-col lg="3">
              <div class="d-flex flex-column">
                <p class="m-0 p-0 font-size-16 text-secondary">
                  {{ $t("marketplace.totalCostOfOrder") }}
                </p>
                <p class="m-0 p-0 font-size-20">
                  {{ order.total_amount }} {{ $t("marketplace.rs") }}
                </p>
              </div>
            </b-col>
            <b-col lg="2">
              <div class="arrow-collapse-open">
                <i class="las la-angle-down text-muted"></i>
              </div>
            </b-col>
            <div :class="`status--${order.status} budge-label`">
              <p>
                {{ $t(`userProfile.statuses.${order.status}`) }}
              </p>
            </div>
          </b-row>
          <b-row v-else class="align-items-center mb-5">
            <b-col lg="3">
              <div class="d-flex flex-column">
                <p class="m-0 p-0 font-size-16 text-secondary">
                  {{ $t("marketplace.orderNumber") }}
                </p>
                <p class="m-0 p-0 font-size-18 text-muted">
                  {{ order.order_number }}
                </p>
              </div>
            </b-col>
            <b-col lg="4">
              <div class="d-flex flex-column">
                <p class="m-0 p-0 font-size-16 text-secondary">
                  {{ $t("marketplace.storeName") }}
                </p>
                <p class="m-0 p-0 font-size-18 text-muted">
                  {{ order.store.name }}
                </p>
              </div>
            </b-col>
            <b-col lg="3">
              <div class="d-flex flex-column">
                <span class="font-size-16 text-secondary">{{
                  $t("marketplace.orderDate")
                }}</span>
                <p class="m-0 p-0 font-size-18 text-muted">
                  {{ order.created_at | formateDate }}
                </p>
              </div>
            </b-col>
            <b-col lg="2">
              <div :class="`status--${order.status} budge-label-inside`">
                <p class="p-0 m-0">
                  {{ $t(`userProfile.statuses.${order.status}`) }}
                </p>
              </div>
            </b-col>
          </b-row>
          <b-collapse
            :id="`more-content-${order.id}`"
            class="p-0"
            v-if="order.order_products"
          >
            <h4 class="mb-3 text-muted font-weight-bold">
              {{ $t("marketplace.products") }}
            </h4>
            <b-row>
              <b-col lg="3">
                <div class="d-flex flex-column">
                  <h5 class="text-muted">
                    {{ $t("marketplace.productName") }}
                  </h5>
                </div>
              </b-col>
              <b-col lg="4">
                <div class="d-flex flex-column">
                  <h5 class="text-muted">{{ $t("marketplace.quantity") }}</h5>
                </div>
              </b-col>
              <b-col lg="3">
                <div class="d-flex flex-column">
                  <h5 class="text-muted">{{ $t("marketplace.price") }}</h5>
                </div>
              </b-col>
              <b-col lg="2"> </b-col>
            </b-row>
            <template v-for="(prodOrder, key) in order.order_products">
              <div :key="key">
                <b-row>
                  <b-col lg="3">
                    <div class="d-flex flex-column">
                      <p class="m-0 p-0 font-size-18">{{ prodOrder.name }}</p>
                    </div>
                  </b-col>
                  <b-col lg="4">
                    <div class="d-flex flex-column">
                      <p class="m-0 p-0 font-size-18">
                        {{ prodOrder.quantityInCart }}
                      </p>
                    </div>
                  </b-col>
                  <b-col lg="3">
                    <div class="d-flex flex-column">
                      <p class="m-0 p-0 font-size-18">
                        {{ prodOrder.price }} {{ $t("marketplace.rs") }}
                      </p>
                    </div>
                  </b-col>
                  <b-col lg="2"> </b-col>
                </b-row>
              </div>
            </template>
            <div class="for-border">
              <b-row>
                <b-col sm="5">
                  <b-button
                    class="print-btn"
                    @click.stop="
                      $router.push({
                        name: 'user-invoice',
                        params: { id: order.id },
                      })
                    "
                  >
                    {{ $t("marketplace.printReceipt") }}
                  </b-button>
                </b-col>
                <b-col sm="7">
                  <div class="hold-total-price">
                    <p class="m-0 p-0 font-size-16 text-secondary">
                      {{ $t("marketplace.totalCostOfOrder") }}
                    </p>
                    <p class="m-0 p-0 font-size-20">
                      {{ order.total_amount }} {{ $t("marketplace.rs") }}
                    </p>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-collapse>
          <span class="close-collapse" v-if="order.id === selectedId">
            <i class="las la-angle-up"></i>
          </span>
        </iq-card>
        <div class="d-flex justify-content-center">
          <b-pagination
            v-if="pagination.pageCount > 1"
            v-model="params.page"
            :total-rows="pagination.total"
            :per-page="pagination.take"
            first-number
            last-number
            class="custom-pagination mb-3 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            align="right"
            @input="getOrders()"
          >
          </b-pagination>
        </div>
      </div>
      <p v-else>
        {{ $t("main.no_data") }}
      </p>
    </div>
  </div>
</template>

<script>
import marketPlace from "@/modules/userProfile/services/userProfile";
import moment from "moment";
import AppButton from "../../../components/buttons/appButton.vue";

export default {
  name: "user-marketplace",
  components: { AppButton },
  data() {
    return {
      params: {
        order_num: "",
        status: "",
        sort: "",
        page: 1,
      },
      orders: [],
      loadingData: false,
      selectedId: "",
      pagination: {},
      sort: [
        {
          name_en: "All",
          name_ar: "الكل",
          value: "",
        },
        {
          name_en: "Newest to oldest",
          name_ar: "من الأحدث للأقدم",
          value: "DESC",
        },
        {
          name_en: "Oldest to newest",
          name_ar: "من الأقدم للأحدث",
          value: "ASC",
        },
      ],
      status: [
        {
          name: this.$t("main.all"),
          value: "",
        },
        {
          name: this.$t("userProfile.statuses.in_progress"),
          value: "in_progress",
        },

        {
          name: this.$t("userProfile.statuses.shipping_to_order"),
          value: "shipping_to_order",
        },
        {
          name: this.$t("userProfile.statuses.rejected"),
          value: "rejected",
        },
        {
          name: this.$t("userProfile.statuses.delivered"),
          value: "delivered",
        },
      ],

      ordersStats: {
        total: null,
        delivered: null,
        in_progress: null,
      },
    };
  },
  filters: {
    formateDate: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
  methods: {
    getOrders() {
      this.loadingData = true;
      marketPlace.getAllOrders(this.params, "").then((res) => {
        this.orders = res.data.data;
        this.pagination = res.data.meta;
        this.loadingData = false;
      });
    },
    async getOrdersStats() {
      const { data } = await marketPlace.getOrdersStats();
      this.ordersStats = data.data;
    },
    downloadInvoice(order) {
      marketPlace.downloadInvoice(order.id).then((res) => {
        window.open(res.data, "_blank");
      });
    },
  },
  created() {
    this.getOrdersStats();
    this.getOrders();
  },
};
</script>

<style lang="scss" scoped>
.product-img {
  height: 100px !important;
}

.font-size-25 {
  font-size: 25px !important;
}

.budge-label {
  white-space: nowrap;
  position: absolute;
  width: 50px !important;
  /*background-color: #EC5D61;*/
  /*color: #fff;*/
  left: 0;
  height: 100% !important;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 !important;
  //border-top-left-radius: 15px !important;
  //border-bottom-left-radius: 15px !important;
  &:lang(en) {
    left: auto;
    right: 0;
  }
}

.budge-label p {
  transform: rotate(90deg);
  transform-origin: center;
  margin: 0 !important;
}

.close-collapse {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -20px;
  background-color: var(--iq-primary);
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  left: calc(50% - 20px);
}

.budge-label-inside {
  /*background-color: #EC5D61;*/
  /*color: #fff;*/
  text-align: center;
  display: inline-block;
  padding: 0 20px;
  border-radius: 0 !important;
}

.arrow-collapse-open {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(128, 128, 128, 0.53);
  border-radius: 5px;
}

.text-decoration-underline {
  text-decoration: underline;
}

.iq-card {
  border-radius: 4px !important;
  overflow: hidden;
  box-shadow: 0 0 6px 4px rgb(120 146 141 / 6%);
}

.order-select {
  background: #fff;
}

.order-search-btn {
  margin-bottom: 1rem !important;
  border-radius: 2px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
}

.for-border {
  &:before {
    content: "";
    width: calc(100% - 50px);
    height: 2px;
    background: #ececec;
    display: block;
  }
}

.print-btn {
  color: #00443d !important;
  border: 1px solid #00443d !important;
  border-radius: 2px !important;
  background: transparent !important;
  transition: all 0.3s ease-in-out;
  margin-top: 1rem !important;
  &:hover {
    background: #e4eae9 !important;
    color: #00443d !important;
    border: 1px solid #00443d !important;
  }
}

.hold-total-price {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  p {
    &:first-of-type {
      margin-left: 0.5rem !important;
      &:lang(en) {
        margin-left: 0 !important;
        margin-right: 0.5rem !important;
      }
    }

    &:last-of-type {
      color: #000;
    }
  }
}
.vs--searchable div {
  background: #fff !important;
}
.budge-label {
  background: rgba(128, 128, 128, 0.3) !important;
}
</style>
